import React ,{useState,useEffect}from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';
import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Checkout2 from './Pages/checkout2';
import Categories from './Pages/Categories';
import Categories1 from './Pages/Categories1';
// import Thankyou from './Pages/Thankyou';
import Header from './components/Header';
import Footer from './components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';












import Privacy from './Pages/Privacy';
import consign from './Pages/consign';
// import Shipping from './Pages/Shipping';
import tracking from './Pages/tracking';
import FAQ from './Pages/FAQ';
import Shop from './Pages/Shop';
// import SaleSubCategoriesProduct from './Pages/SaleSubCategoriesProduct';
// import menSubCategoriesProduct from './Pages/menSubCategoriesProduct';
// import womenSubCategoriesProduct from './Pages/womenSubCategoriesProduct';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
import SizeProductDetail from './Pages/SizeProductDetail';
import thankyou from './Pages/Thankyou';
import chat from './Pages/chat';
// import WomenOnlySubCategoriesProduct from './Pages/WomenOnlySubCategoriesProduct';
// import MenOnlySubCategoriesProduct from './Pages/MenOnlySubCategoriesProduct';
import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';
import whislist from './Pages/whislist';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';

import whatapps from './assets/images/what.png';

// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Term from './Pages/Term';
import ab from './Pages/ab';
import expertpick from './Pages/expertpick';
import recommendedproduct from './Pages/recommendedproduct';
import newArrival from './Pages/newArrival';
import Error from './Pages/Error';
import CorporateEquiries from './Pages/CorporateEquiries';
import Trafficfines from './Pages/Trafficfines';
import testimonials from './Pages/testimonials';




import RentacarDubai from './Pages/rentacarDubai';
import rentacarBurDubai from './Pages/rentacarBur Dubai';
import RentacarDubaiDowntown from './Pages/rentacarDubai Downtown';
import rentacarDubaiHills from './Pages/rentacarDubai Hills';
import rentacarDubaiSiliconOasis from './Pages/rentacarDubaiSiliconOasis';
import rentacarKarama from './Pages/rentacarKarama';
import rentacarDeira from './Pages/rentacarDeira';
import rentacarDubaiFrame from './Pages/rentacarDubaiFrame';
import rentacarEmiratesHills from './Pages/rentacarEmiratesHills';
import rentacarJumeirahBeach from './Pages/rentacarJumeirahBeach';
import rentacarPalmJumeirah from './Pages/rentacarPalmJumeirah';
import rentacarBurjAlArab from './Pages/rentacarBurjAlArab';
import rentacarAlSatwa from './Pages/rentacarAlSatwa';
import rentacarBurjKhalifa from './Pages/rentacarBurjKhalifa';
import rentacarAlQuoz from './Pages/rentacarAlQuoz';
import rentacaralKarama from './Pages/rentacaralKarama';
import rentacarOneOnlyOneZaabeel from './Pages/rentacarOneOnlyOneZaabeel';
import rentacarDubai1 from './Pages/rentacar Dubai';
import rentacarUAE from './Pages/rentacarUAE';
import rentacarUAE1 from './Pages/rentacar UAE';
import rentacarAbuDhabi from './Pages/rentacarAbuDhabi';
import rentacarAjman from './Pages/rentacarAjman';
import rentacarDubaiFountain from './Pages/rentacarDubaiFountain';
import rentacarSkiDubai from './Pages/rentacarSkiDubai';
import rentacarDubaiMiracleGarden from './Pages/rentacarDubaiMiracleGarden';
import rentacarDesertSafari from './Pages/rentacarDesertSafari';
import rentacarSharjah from './Pages/rentacarSharjah';
import rentacarRasAlKhaimah from './Pages/rentacarRasAlKhaimah';
import rentacarUmmAlQuwain from './Pages/rentacarUmmAlQuwain';
import rentacarAlAin from './Pages/rentacarAlAin';
import rentacarFujairah from './Pages/rentacarFujairah';
import rentacarSheikhZayedRoad from './Pages/rentacarSheikh Zayed Road';
import rentacarDubaiMarina from './Pages/rentacarDubaiMarina';




import Rentacar1Dubai from './Pages/rentacar1Dubai';
import rentacar1BurDubai from './Pages/rentacar1Bur Dubai';
import Rentacar1DubaiDowntown from './Pages/rentacar1Dubai Downtown';
import rentacar1DubaiHills from './Pages/rentacar1Dubai Hills';
import rentacar1DubaiSiliconOasis from './Pages/rentacar1DubaiSiliconOasis';
import rentacar1Karama from './Pages/rentacar1Karama';
import rentacar1Deira from './Pages/rentacar1Deira';
import rentacar1DubaiFrame from './Pages/rentacar1DubaiFrame';
import rentacar1EmiratesHills from './Pages/rentacar1EmiratesHills';
import rentacar1JumeirahBeach from './Pages/rentacar1JumeirahBeach';
import rentacar1PalmJumeirah from './Pages/rentacar1PalmJumeirah';
import rentacar1BurjAlArab from './Pages/rentacar1BurjAlArab';
import rentacar1AlSatwa from './Pages/rentacar1AlSatwa';
import rentacar1BurjKhalifa from './Pages/rentacar1BurjKhalifa';
import rentacar1AlQuoz from './Pages/rentacar1AlQuoz';
import rentacar1alKarama from './Pages/rentacar1alKarama';
import rentacar1OneOnlyOneZaabeel from './Pages/rentacar1OneOnlyOneZaabeel';
import rentacar1Dubai1 from './Pages/rentacar1 Dubai';
import rentacar1UAE from './Pages/rentacar1UAE';
import rentacar1UAE1 from './Pages/rentacar1 UAE';
import rentacar1AbuDhabi from './Pages/rentacar1AbuDhabi';
import rentacar1Ajman from './Pages/rentacar1Ajman';
import rentacar1DubaiFountain from './Pages/rentacar1DubaiFountain';
import rentacar1SkiDubai from './Pages/rentacar1SkiDubai';
import rentacar1DubaiMiracleGarden from './Pages/rentacar1DubaiMiracleGarden';
import rentacar1DesertSafari from './Pages/rentacar1DesertSafari';
import rentacar1Sharjah from './Pages/rentacar1Sharjah';
import rentacar1RasAlKhaimah from './Pages/rentacar1RasAlKhaimah';
import rentacar1UmmAlQuwain from './Pages/rentacar1UmmAlQuwain';
import rentacar1AlAin from './Pages/rentacar1AlAin';
import rentacar1Fujairah from './Pages/rentacar1Fujairah';
import rentacar1SheikhZayedRoad from './Pages/rentacar1Sheikh Zayed Road';
import rentacar1DubaiMarina from './Pages/rentacar1DubaiMarina';






import Rentacar12Dubai from './Pages/rentacar1Dubai - Copy';
import rentacar12BurDubai from './Pages/rentacar1Bur Dubai - Copy';
import Rentacar12DubaiDowntown from './Pages/rentacar1Dubai Downtown - Copy';
import rentacar12DubaiHills from './Pages/rentacar1Dubai Hills - Copy';
import rentacar12DubaiSiliconOasis from './Pages/rentacar1DubaiSiliconOasis - Copy';
import rentacar12Karama from './Pages/rentacar1Karama - Copy';
import rentacar12Deira from './Pages/rentacar1Deira - Copy';
import rentacar12DubaiFrame from './Pages/rentacar1DubaiFrame - Copy';
import rentacar12EmiratesHills from './Pages/rentacar1EmiratesHills - Copy';
import rentacar12JumeirahBeach from './Pages/rentacar1JumeirahBeach - Copy';
import rentacar12PalmJumeirah from './Pages/rentacar1PalmJumeirah - Copy';
import rentacar12BurjAlArab from './Pages/rentacar1BurjAlArab - Copy';
import rentacar12AlSatwa from './Pages/rentacar1AlSatwa - Copy';
import rentacar12BurjKhalifa from './Pages/rentacar1BurjKhalifa - Copy';
import rentacar12AlQuoz from './Pages/rentacar1AlQuoz - Copy';
import rentacar12alKarama from './Pages/rentacar1alKarama - Copy';
import rentacar12OneOnlyOneZaabeel from './Pages/rentacar1OneOnlyOneZaabeel - Copy';
// import rentacar12Dubai1 from './Pages/rentacar1 Dubai - Copy';
import rentacar12UAE from './Pages/rentacar1UAE - Copy';
// import rentacar12UAE1 from './Pages/rentacar1 UAE - Copy';
import rentacar12AbuDhabi from './Pages/rentacar1AbuDhabi - Copy';
import rentacar12Ajman from './Pages/rentacar1Ajman - Copy';
import rentacar12DubaiFountain from './Pages/rentacar1DubaiFountain - Copy';
import rentacar12SkiDubai from './Pages/rentacar1SkiDubai - Copy';
import rentacar12DubaiMiracleGarden from './Pages/rentacar1DubaiMiracleGarden - Copy';
import rentacar12DesertSafari from './Pages/rentacar1DesertSafari - Copy';
import rentacar12Sharjah from './Pages/rentacar1Sharjah - Copy';
import rentacar12RasAlKhaimah from './Pages/rentacar1RasAlKhaimah - Copy';
import rentacar12UmmAlQuwain from './Pages/rentacar1UmmAlQuwain - Copy';
import rentacar12AlAin from './Pages/rentacar1AlAin - Copy';
import rentacar12Fujairah from './Pages/rentacar1Fujairah - Copy';
import rentacar12SheikhZayedRoad from './Pages/rentacar1Sheikh Zayed Road - Copy';
import rentacar12DubaiMarina from './Pages/rentacar1DubaiMarina - Copy';


import rent0 from './Pages/rent1';
import rent1 from './Pages/rent1  (1)';
import rent2 from './Pages/rent1  (2)';
import rent3 from './Pages/rent1  (3)';
import rent4 from './Pages/rent1  (4)';
import rent5 from './Pages/rent1  (5)';
import rent6 from './Pages/rent1  (6)';
import rent7 from './Pages/rent1  (7)';
import rent8 from './Pages/rent1  (8)';
import rent9 from './Pages/rent1  (9)';
import rent10 from './Pages/rent1  (10)';
import rent11 from './Pages/rent1  (11)';
import rent12 from './Pages/rent1  (12)';
import rent13 from './Pages/rent1  (13)';
import rent14 from './Pages/rent1  (14)';
import rent15 from './Pages/rent1  (15)';
import rent16 from './Pages/rent1  (16)';
import rent17 from './Pages/rent1  (17)';
import rent18 from './Pages/rent1  (18)';
import rent19 from './Pages/rent1  (19)';
import rent20 from './Pages/rent1  (20)';
import rent21 from './Pages/rent1  (21)';
import rent22 from './Pages/rent1  (22)';
import rent23 from './Pages/rent1  (23)';
import rent24 from './Pages/rent1  (24)';
import rent25 from './Pages/rent1  (25)';
import rent26 from './Pages/rent1  (26)';
import rent27 from './Pages/rent1  (27)';
import rent28 from './Pages/rent1  (28)';
import rent29 from './Pages/rent1  (29)';
import rent30 from './Pages/rent1  (30)';
import rent31 from './Pages/rent1  (31)';
import rent32 from './Pages/rent1  (32)';
import rent33 from './Pages/rent1  (33)';
import rent34 from './Pages/rent1  (34)';
import rent35 from './Pages/rent1  (35)';
import rent36 from './Pages/rent1  (36)';
import rent37 from './Pages/rent1  (37)';
import rent38 from './Pages/rent1  (38)';



import Text from './Pages/text';
import Redirect from './Pages/redirect';
import Montly from './Pages/montly';
import Weekly from './Pages/weekly';
// import submain from './Pages/submain';

// import Privacy from './Pages/Privacy';
// import MainUserSignup from './Pages/MainUserSignup';
// import MainUserLogin from './Pages/MainUserLogin';
// import forgetpassaccount from './Pages/forgetpassaccount';


// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';


// import $ from 'jquery';


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
import "./bootstrap.min.css"
import "./all.css"
import "./custom.css"
import "./bootsnav.css"
import "./responsive.css"
import "./style1.css"
import "./css/style.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";

import "./css1/custom.css";
import "./css1/responsive.css";
import "./css1/bootstrap-select.css";
import "./css1/baguetteBox.min.css";
import "./css1/owl.carousel.min.css";
import "./css1/bootsnav.css";
import "./css1/code_animate.css";
import "./css1/carousel-ticker.css";
import "./css1/superslides.css";
import "./css1/all.css";
import "./css1/style.css";

import "./css1/bootsnav";

import "./asset1/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
import "./asset1/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
import "./asset1/css/plugins/jquery.countdown.css";
import "./asset1/css/plugins/magnific-popup/magnific-popup.css";
import "./asset1/css/style.css";
import "./asset1/css/skins/skin-demo-26.css";
import "./asset1/css/demos/demo-26.css";


import Navbar from "../src/components/Navbar";
import "../src/dist/styles.css";
import "./style6.css"

// import Footer from "../components/Footer";

import whatapps1 from './assets/images/png.png';
import car1 from './assets/images/car1.png';
import car2 from './assets/images/car2.png';
import car3 from './assets/images/car3.png';


function App() {
  
  const [showPopup, setShowPopup] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [photo, setphoto] = useState([car1,car2,car3]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % 3);
    }, 2000); // 3 seconds

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex,photo]);


  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="App" >  
         <a href="tel:+971563619373" target="_blank"><img src={whatapps1} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "15px",width:"50px",height : "50px"}}/></a>
                  
     <a href="https://wa.me/971563619373?text=Hi *Falcon Rides*! I need more info about Car Rental https://falcon-rides.com" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "72%" , right : "10px",width:"60px",height : "60px"}}/></a>





     {/* <button onClick={()=>handleShowPopup()}>Show Popup</button>
      // {showPopup && ( */}
        {/* // <Popup imageUrl={imageUrl} onClose={handleClosePopup} /> */}

{showPopup ?
<a href="tel:+971563619373">
        <div className="popup-container">
      <div className="popup-content">
        <img src={photo[currentIndex]} alt="Popup Image" style={{margin:"20px auto 0px auto"}}/>
        <span className="close-icon" onClick={()=>handleClosePopup(!showPopup)}>&times;</span>
      </div>
    </div>

</a>
:""

}



      {/* )} */}








      <Router>

        {/* <Header /> */}
        <Navbar />
        
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/term-policy' component={Term} />
          <Route exact path='/Shipping' component={Shipping} />
          <Route exact path='/Corporate-Equiries' component={CorporateEquiries} />
          <Route exact path='/Text' component={Text} />
          <Route exact path='/redirect' component={Redirect} />
          <Route exact path='/product/new-arrival' component={newArrival} />
          <Route exact path='/product/recommended-product' component={recommendedproduct} />
          <Route exact path='/product/expert-pick' component={expertpick} />

          <Route exact path='/carrental-dubai' component={rentacarDubai1} />
          <Route exact path='/car-rental-UAE' component={rentacarUAE1} />
          <Route exact path='/car-rental-service-in-UAE' component={rentacarUAE} />


          <Route exact path='/car-rental-service-in-dubai-frame' component={rentacarDubaiFrame} />
          <Route exact path='/car-rental-service-in-emirates-hills' component={rentacarEmiratesHills} />
          <Route exact path='/car-rental-service-in-jumeirah-beach' component={rentacarJumeirahBeach} />
          <Route exact path='/car-rental-service-in-palm-jumeirah' component={rentacarPalmJumeirah} />
          <Route exact path='/car-rental-service-in-burj-al-arab' component={rentacarBurjAlArab} />
          <Route exact path='/car-rental-service-in-al-Satwa' component={rentacarAlSatwa} />
          <Route exact path='/car-rental-service-in-burj-khalifa' component={rentacarBurjKhalifa} />
          <Route exact path='/car-rental-service-in-al-quoz' component={rentacarAlQuoz} />
          <Route exact path='/car-rental-service-in-al-karama' component={rentacaralKarama} />
          <Route exact path='/car-rental-service-in-dubai-marina' component={rentacarDubaiMarina} />
          <Route exact path='/car-rental-service-in-dubai' component={RentacarDubai} />
          <Route exact path='/car-rental-service-in-bur-dubai' component={rentacarBurDubai} />
          <Route exact path='/car-rental-service-in-dubai-downtown' component={RentacarDubaiDowntown} />
          <Route exact path='/car-rental-service-in-dubai-hills' component={rentacarDubaiHills} />
          <Route exact path='/car-rental-service-in-karama' component={rentacarKarama} />
          <Route exact path='/car-rental-service-in-sheikh-zayed-road' component={rentacarSheikhZayedRoad} />
          <Route exact path='/car-rental-service-in-dubai-silicon-oasis' component={rentacarDubaiSiliconOasis} />
          <Route exact path='/car-rental-service-in-Deira' component={rentacarDeira} />
          <Route exact path='/car-rental-service-in-Abu-Dhabi' component={rentacarAbuDhabi} />
          <Route exact path='/car-rental-service-in-Ajman' component={rentacarAjman} />
          <Route exact path='/car-rental-service-in-Dubai-Fountain' component={rentacarDubaiFountain} />
          <Route exact path='/car-rental-service-in-Ski-Dubai' component={rentacarSkiDubai} />
          <Route exact path='/car-rental-service-in-Dubai-Miracle-Garden' component={rentacarDubaiMiracleGarden} />
          <Route exact path='/car-rental-service-in-Desert-Safari' component={rentacarDesertSafari} />
          <Route exact path='/car-rental-service-in-Sharjah' component={rentacarSharjah} />
          <Route exact path='/car-rental-service-in-Ras-Al-Khaimah' component={rentacarRasAlKhaimah} />
          <Route exact path='/car-rental-service-in-Umm-Al-Quwain' component={rentacarUmmAlQuwain} />
          <Route exact path='/car-rental-service-in-Fujairah' component={rentacarFujairah} />
          <Route exact path='/car-rental-service-in-Al-Ain' component={rentacarAlAin} />
          <Route exact path="/car-rental-service-in-One-&-Only-One-Za'abeel" component={rentacarOneOnlyOneZaabeel} />
        
          <Route exact path='/rentacar-dubai' component={rentacar1Dubai1} />
          <Route exact path='/rent-a-car-in-UAE' component={rentacar1UAE1} />
          <Route exact path='/rent-a-car-service-in-UAE' component={rentacar1UAE} />


          <Route exact path='/rent-a-car-service-in-dubai-frame' component={rentacar1DubaiFrame} />
          <Route exact path='/rent-a-car-service-in-emirates-hills' component={rentacar1EmiratesHills} />
          <Route exact path='/rent-a-car-service-in-jumeirah-beach' component={rentacar1JumeirahBeach} />
          <Route exact path='/rent-a-car-service-in-palm-jumeirah' component={rentacar1PalmJumeirah} />
          <Route exact path='/rent-a-car-service-in-burj-al-arab' component={rentacar1BurjAlArab} />
          <Route exact path='/rent-a-car-service-in-al-Satwa' component={rentacar1AlSatwa} />
          <Route exact path='/rent-a-car-service-in-burj-khalifa' component={rentacar1BurjKhalifa} />
          <Route exact path='/rent-a-car-service-in-al-quoz' component={rentacar1AlQuoz} />
          <Route exact path='/rent-a-car-service-in-al-karama' component={rentacar1alKarama} />
          <Route exact path='/rent-a-car-service-in-dubai-marina' component={rentacar1DubaiMarina} />
          <Route exact path='/rent-a-car-service-in-dubai' component={Rentacar1Dubai} />
          <Route exact path='/rent-a-car-service-in-bur-dubai' component={rentacar1BurDubai} />
          <Route exact path='/rent-a-car-service-in-dubai-downtown' component={Rentacar1DubaiDowntown} />
          <Route exact path='/rent-a-car-service-in-dubai-hills' component={rentacar1DubaiHills} />
          <Route exact path='/rent-a-car-service-in-karama' component={rentacar1Karama} />
          <Route exact path='/rent-a-car-service-in-sheikh-zayed-road' component={rentacar1SheikhZayedRoad} />
          <Route exact path='/rent-a-car-service-in-dubai-silicon-oasis' component={rentacar1DubaiSiliconOasis} />
          <Route exact path='/rent-a-car-service-in-Deira' component={rentacar1Deira} />
          <Route exact path='/rent-a-car-service-in-Abu-Dhabi' component={rentacar1AbuDhabi} />
          <Route exact path='/rent-a-car-service-in-Ajman' component={rentacar1Ajman} />
          <Route exact path='/rent-a-car-service-in-Dubai-Fountain' component={rentacar1DubaiFountain} />
          <Route exact path='/rent-a-car-service-in-Ski-Dubai' component={rentacar1SkiDubai} />
          <Route exact path='/rent-a-car-service-in-Dubai-Miracle-Garden' component={rentacar1DubaiMiracleGarden} />
          <Route exact path='/rent-a-car-service-in-Desert-Safari' component={rentacar1DesertSafari} />
          <Route exact path='/rent-a-car-service-in-Sharjah' component={rentacar1Sharjah} />
          <Route exact path='/rent-a-car-service-in-Ras-Al-Khaimah' component={rentacar1RasAlKhaimah} />
          <Route exact path='/rent-a-car-service-in-Umm-Al-Quwain' component={rentacar1UmmAlQuwain} />
          <Route exact path='/rent-a-car-service-in-Fujairah' component={rentacar1Fujairah} />
          <Route exact path='/rent-a-car-service-in-Al-Ain' component={rentacar1AlAin} />
          <Route exact path="/rent-a-car-service-in-One-&-Only-One-Za'abeel" component={rentacar1OneOnlyOneZaabeel} />
        


          <Route exact path="/rent-a-car-in-dubai-online-at-affordable-prices" component={rent0} />
          <Route exact path="/rent-a-car-dubai-economy-and-luxury-car-rental-uae" component={rent1} />
          <Route exact path="/self-drive-cars-in-dubai-car-rental-services" component={rent2} />
          <Route exact path="/dubai-international-airport-term-1-car-rental" component={rent3} />
          <Route exact path="/rent-a-car-cheap-car-rental-car-hire-in-dubai-uae" component={rent4} />
          <Route exact path="/car-rental-dubai-search-cheap-rental-car-deals" component={rent5} />
          <Route exact path="/cheap-car-rental-in-dubai-find-and-compare-deals" component={rent6} />
          <Route exact path="/weekly-car-rental-in-dubai-rent-a-car-with-no-hidden-charges" component={rent7} />
          <Route exact path="/car-rental-rent-a-car-dubai-at-lowest-price" component={rent8} />
          <Route exact path="/rent-a-car-in-dubai-car-rental-agency-in-uae" component={rent9} />
          <Route exact path="/rent-a-car-in-dubai-car-rental-dubai" component={rent10} />
          <Route exact path="/rent-a-car-dubai-best-car-rental-offers-monthly-and-daily" component={rent11} />
          <Route exact path="/rent-a-car-al-karama-cheap-car-rental-hire-in-dubai" component={rent12} />
          <Route exact path="/rent-a-car-karama-at-cheapest-rates-falcon-rides-car-rental-dubai" component={rent13} />
          <Route exact path="/affordable-car-rental-al-karama-dubai" component={rent14} />













          <Route exact path="/budget-friendly-car-rental-in-karama-by-quick-lease" component={rent15} />
          <Route exact path="/our-car-rental-fleet-per-day-aed-100-per-week-aed-1000-per-month-aed-1536" component={rent16} />
          <Route exact path="/rent-a-car-karama-hire-quality-cars-at-cost-effective" component={rent17} />
          <Route exact path="/car-rental-in-al-karama-hire-cheap-car-in-al-karama" component={rent18} />
          <Route exact path="/rent-a-car-in-karama-in-uae-on-the-map-celia-car-rental" component={rent19} />
          <Route exact path="/rent-a-car-in-al-karama-with-great-rent-a-car" component={rent20} />
          <Route exact path="/rent-a-car-in-al-karama-dubai-cheap-car-hire-dubai" component={rent21} />
          <Route exact path="/cheap-car-rental-al-karama-rent-a-cars-royal-dubai-uae" component={rent22} />
          <Route exact path="/car-rental-karama-dubai-united-arab-emirates" component={rent23} />
          <Route exact path="/rent-a-car-karama-dubai-daily-and-long-term-car-hire-al-karama" component={rent24} />
          <Route exact path="/luxury-car-rental-al-karama-rent-a-car-royal-dubai-uae" component={rent25} />
          <Route exact path="/rent-a-car-in-al-karama-dubai-best-deals-and-offers" component={rent26} />
          <Route exact path="/rent-a-car-in-al-karama-dubai-affordable-reliable" component={rent27} />

          <Route exact path='/do-you-reside-in-al-karama-and-wish-to-hire-a-car-to-make-your-travels-easier?' component={rent28} />
          <Route exact path='/best-deals-on-car-rentals-in-al-karama-dubai-daily-weekly-and-monthly-rates-available-book-now!' component={rent29} />
          <Route exact path='/helps-you-to-find-perfect-car-for-your-next-ride-with-rent-a-car-dubai-in-al-karama' component={rent30} />
          <Route exact path='/rent-a-car-at-cheap-price-in-al-karama' component={rent31} />
          <Route exact path='/rent-a-car-has-a-unique-list-of-cars-available-for-rent-in-al-karama-dubai!' component={rent32} />
          <Route exact path='/cheap-car-rental-in-al-karama-dubai-uae-price-from-aed-90-per-day' component={rent33} />
          <Route exact path='/find-cheap-car-rental-deals-for-karama-at-falcon-rides-com' component={rent34} />
          <Route exact path='/looking-for-a-car-rental-company-in-karama-dubai' component={rent35} />
          <Route exact path='/rent-a-car-in-al-karama-dubai-discover-the-vibrant-neighborhood-of-al-karama-with-our-affordable-car-rental-options' component={rent36} />
          <Route exact path='/the-cost-of-renting-a-car-in-al-karama-varies-depending-on-the-type-of-vehicle-you-choose-and-the-rental-duration' component={rent37} />
          <Route exact path='/renting-a-car-in-al-karama-with-car-rental-dbx-is-not-only-convenient-but-also-remarkably-affordable' component={rent38} />

       






        
          <Route exact path='/rent-a-car-UAE' component={rentacar12UAE} />
          <Route exact path='/rent-a-car-dubai' component={Rentacar12Dubai} />
          <Route exact path='/rent-a-car-dubai-frame' component={rentacar12DubaiFrame} />
          <Route exact path='/rent-a-car-emirates-hills' component={rentacar12EmiratesHills} />
          <Route exact path='/rent-a-car-jumeirah-beach' component={rentacar12JumeirahBeach} />
          <Route exact path='/rent-a-car-palm-jumeirah' component={rentacar12PalmJumeirah} />
          <Route exact path='/rent-a-car-burj-al-arab' component={rentacar12BurjAlArab} />
          <Route exact path='/rent-a-car-al-Satwa' component={rentacar12AlSatwa} />
          <Route exact path='/rent-a-car-burj-khalifa' component={rentacar12BurjKhalifa} />
          <Route exact path='/rent-a-car-al-quoz' component={rentacar12AlQuoz} />
          <Route exact path='/rent-a-car-al-karama' component={rentacar12alKarama} />
          <Route exact path='/rent-a-car-dubai-marina' component={rentacar12DubaiMarina} />
          <Route exact path='/rent-a-car-dubai' component={Rentacar12Dubai} />
          <Route exact path='/rent-a-car-bur-dubai' component={rentacar12BurDubai} />
          <Route exact path='/rent-a-car-dubai-downtown' component={Rentacar12DubaiDowntown} />
          <Route exact path='/rent-a-car-dubai-hills' component={rentacar12DubaiHills} />
          <Route exact path='/rent-a-car-karama' component={rentacar12Karama} />
          <Route exact path='/rent-a-car-sheikh-zayed-road' component={rentacar12SheikhZayedRoad} />
          <Route exact path='/rent-a-car-dubai-silicon-oasis' component={rentacar12DubaiSiliconOasis} />
          <Route exact path='/rent-a-car-Deira' component={rentacar12Deira} />
          <Route exact path='/rent-a-car-Abu-Dhabi' component={rentacar12AbuDhabi} />
          <Route exact path='/rent-a-car-Ajman' component={rentacar12Ajman} />
          <Route exact path='/rent-a-car-Dubai-Fountain' component={rentacar12DubaiFountain} />
          <Route exact path='/rent-a-car-Ski-Dubai' component={rentacar12SkiDubai} />
          <Route exact path='/rent-a-car-Dubai-Miracle-Garden' component={rentacar12DubaiMiracleGarden} />
          <Route exact path='/rent-a-car-Desert-Safari' component={rentacar12DesertSafari} />
          <Route exact path='/rent-a-car-Sharjah' component={rentacar12Sharjah} />
          <Route exact path='/rent-a-car-Ras-Al-Khaimah' component={rentacar12RasAlKhaimah} />
          <Route exact path='/rent-a-car-Umm-Al-Quwain' component={rentacar12UmmAlQuwain} />
          <Route exact path='/rent-a-car-Fujairah' component={rentacar12Fujairah} />
          <Route exact path='/rent-a-car-Al-Ain' component={rentacar12AlAin} />
          <Route exact path="/rent-a-car-One-&-Only-One-Za'abeel" component={rentacar12OneOnlyOneZaabeel} />









          <Route exact path='/shop-all' component={ab} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/traffic-fines' component={Trafficfines} />
          <Route exact path='/favorite' component={Favorite} />
          <Route exact path='/whislist' component={whislist} />
          <Route exact path='/thankyou' component={thankyou} />
          <Route exact path='/consign' component={consign} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/checkout2' component={Checkout2} />
          <Route exact path='/FAQ' component={FAQ} />
          <Route exact path='/chat' component={chat} />
          <Route exact path='/cart' component={Card} />
          <Route exact path='/all-shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/track' component={tracking} />
          <Route exact path='/testimonials' component={testimonials} />
          <Route exact path='/search/product-item' component={SearchData} />
          {/* <Route exact path='/ab' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          <Route exact path='/main-categories/:categoriesName' component={Categories} />
          {/* <Route exact path='/main-categories/:categoriesName' component={Categories} /> */}
          <Route exact path='/main-categories-car-rental/:categoriesName' component={Categories1} />
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />
          <Route exact path='/categories-montly/:categoriesName/all-products/:productName/:productId' component={Montly} />
          <Route exact path='/categories-weekly/:categoriesName/all-products/:productName/:productId' component={Weekly} />
          {/* <Route exact path='/main-categories/:categoriesName/all-products/:productName/:productId' component={submain} /> */}
          <Route exact path='/main-categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SizeProductDetail} />

          <Route path='*' component={Error} />

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
